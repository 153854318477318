<template>
  <div :class="(showMessageBar?'':'d-none') + '  message-bar'">
    <div :class="messageClass">
      <div>{{ currentMessage }}
        <button class='close-btn' v-if="showMessageBar" @click='closeMessageBar'> X</button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';
export default {
  computed: {
    ...mapGetters(['messages']),
    showMessageBar() {
      return this.messages.message && this.messages.message.length > 0;
    },
    messageClass() {
      let mgClass = this.messages.messageClass;
      return 'panel panel-' + mgClass + " " + (this.showMessageBar ? "" : "hidden");
    },
    currentMessage() {
      return this.messages.message;
    }
  },
  methods: {
    ...mapActions(['clearMessage']),
    closeMessageBar() {
      this.clearMessage();
    }
  }
}
</script>

<style scoped>
@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
.message-bar {
  position: fixed;
  top: 10px;
  left: 50%;
  width: 50%;
  min-height: 50px;
  z-index: 9999;
  margin-left: -25%;
  animation-duration: 3s;
  animation-name: slidein;
}

.message-bar .close-btn {
  position: absolute;
  top: 1;
  right: 0;
  color: #FFF;
  background-color: rgb(50, 63, 63);
}
.message-bar .panel {
  border-color: transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-right: 25px;
  border-radius: 5px;
}
.panel {
  padding: 10px 15px;
}
.panel-danger {
  background-color: #d9534f;
  color: #FFF;
}
.panel-success {
  background-color: #4da34d;
  color: #FFF;
}
.panel-warning {
  background-color: #e9a84e;
  color: #FFF;
}
</style>