import {createWebHistory, createRouter} from 'vue-router'
import SignIn from '@/views/SignIn'
import Create from '@/views/Create'
import Help from '@/views/Help'
import Retrieve from '@/views/Retrieve'
import Terms from '@/views/Terms'
import Privacy from '@/views/Privacy'

// new pages from alvernoDat
import Store from '@/alvernoDat/components/Store.vue'
import ShoppingCart from '@/alvernoDat/components/cart/ShoppingCart'
import ProductDetails from '@/alvernoDat/components/product/ProductDetails'
import Checkout from '@/alvernoDat/components/cart/Checkout'
import OrderHistory from '@/alvernoDat/components/orderhistory/OrderHistory'
import Refund from '@/alvernoDat/components/orderhistory/Refund'

const routes = [
  {
    path: '/login/login',
    alias: ['/oauth/login', '/dat/login', '/oauth/authorize'],
    name: 'Sign In',
    component: SignIn,
  },
  {
    path: '/',
    redirect: '/login/login'
  },
  {
    path: '/login/help',
    name: 'Help',
    component: Help,
  },
  {
    path: '/login/retrieve-password',
    name: 'Retrieve',
    component: Retrieve,
  },
  {
    path: '/login/create-account',
    alias: '/oauth/create-account',
    name: 'Create',
    component: Create,
  },
  {
    path: '/login/common/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/login/common/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/login/alverno',
    alias: ['/dat/alverno'],
    name: 'store',
    component: Store 
  },
  {
    path: '/login/cart',
    alias: ['/dat/cart'],
    name: 'cart',
    component: ShoppingCart
  },
  {
    path: '/login/product/:id',
    alias: ['/dat/product/:id'],
    name: 'product',
    component: ProductDetails
  },
  {
    path: '/login/checkout',
    alias: ['/dat/checkout'],
    name: 'checkout',
    component: Checkout
  },
  {
    path: '/login/order-history',
    alias: ['/dat/order-history'],
    name: 'orderHistory',
    component: OrderHistory
  },

  {
    path: '/login/refund/:id',
    alias: ['/dat/refund/:id'],
    name: 'refund',
    component: Refund,
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router