const state = {
	cartItemList: [],
	transactionId: '',
	stripeClientSecret: '',
	paymentIntentId: '',
	orderId:'',
	cartIsAssigned:false,
}

const mutations = {
	'SET_CART' (state, productList) {
		if (productList) {
			productList.cartTests.forEach(function(test){
				test.price = test.price/100
			});
			state.cartItemList = productList.cartTests;
			
		}else{
			state.cartItemList=[];
			state.cartIsAssigned=false;
		}
		console.log(state.cartItemList)
	},
	'SET_TRANSACTIONID' (state, transactionId) {
		state.transactionId = transactionId
	},
	'SET_PAYMENT_INTENTION' (state, data) {
		state.stripeClientSecret = data.stripeClientSecret
		state.paymentIntentId = data.stripePaymentIntentId
		state.orderId = data.id
		console.log(state.orderId)
	},
	'SET_ASSIGN_STATUS' (state) {
		state.cartIsAssigned = !state.cartIsAssigned;
	}

}

const actions = {
	clearCart: ({commit}) => {
		commit('SET_CART');
	}
}

const getters = {
	cartItemList: (state) => {
		return state.cartItemList;
	},
	cartValue: (state) => {
		let res = 0;
		state.cartItemList.map(item => {
			res += item.price * item.quantity;
		});
		return res;
	},
	transactionID: (state) => {
		return state.transactionId;
	},
	stripeClientSecret: (state) => {
		return state.stripeClientSecret;
	},
	paymentIntentId: (state) => {
		return state.paymentIntentId;
	},
	orderId: (state) => {
		return state.orderId;
	},
	isAssigned: (state) => {
		return state.cartIsAssigned;
	}

}

export default {
	state,
	mutations,
	actions,
	getters
}
