import http from '../../services/httpService';
import config from '../../config.json';

var transactionId = "";

// GET LAB1 TESTS LIST
export async function listenToProductList({commit}) {
	const data = await http.get(config.apiUrl + "/admin/api/lab-order/1/tests")
	.then(res=>{
		return res.data 
	})
	return commit('UPDATE_PRODUCT_LIST',data)  // use in ./modeuls/products.js, assgin the response data to local product list 
}

// ADD TEST TO THE CART 
export async function addTestToCart({commit}, test) {
	let body = { 
				"testId" : test.testId,
				"transactionId":transactionId  // at the first call, the transaction ID is empty
				}
	const data = await http.post(config.apiUrl+"/admin/api/lab-order/cart/test/add", body)
	.then(res=>{
		return res.data
	})
	// after first call, we have the transaction Id, now we need to update it and use it for the next call. 
	commit('SET_TRANSACTIONID', data.transactionId) // use in ./modules/cart.js
	transactionId = data.transactionId
	// present message bar 
	let message_obj = {
		message: `Add ${test.name} to cart successfully`,
		messageClass: "success",
		autoClose: true
	}
	commit('ADD_MESSAGE', message_obj); // use in ./modeuls/message.js
	// get the modified shopping cart
	getShoppingCart({commit}, transactionId)
	// mark the test as added
	commit('ADD_STATUS', test.testId) // use in ./modeuls/products.js
}

// REMOVE A TEST FROM THE CART
export async function removeTestInCart({commit},test) {
	let body = { 
				"quantity" : -1,
				"testId" : test.testId,
				"transactionId":transactionId 
				}
	await http.post(config.apiUrl+"/admin/api/lab-order/cart/test/add", body).then(res=>{
		return res.data
	})
	// get the modified cart
	getShoppingCart({commit}, transactionId)
	// mark the removed test as not added
	commit('REMOVE_STATUS', test.testId) // use in ./modeuls/products.js
}

// GET THE SHOPPING CART BY TRANSACTION ID 
// This function will only be called when frontend trigger add test to the cart or remove test from cart function.
export async function getShoppingCart({commit}, transactionId) {
	console.log(transactionId)
	let data = await http.get(config.apiUrl + "/admin/api/lab-order/cart/?transactionId=" + transactionId)
	.then(response => {return response.data})
	if (data) {
		commit('SET_CART',data) // use in ./modules/cart.js
	} else {
		console.log("Empty Cart");
	}
}

// When user try to pay the cart, the cart will be assigned to the patient, before user pay the cart, the cart will be save as user's cart.
export async function assignCartToPatient({commit},transactionId) {
	console.log(transactionId)
	await http.post(config.apiUrl + "/admin/api/lab-order/cart/patient/assign?transactionId="+transactionId)
	console.log("Cart assigned!!")
	commit('SET_ASSIGN_STATUS') // use in ./modules/cart.js

}

// create a payment intention, later on we are going to use the intention ID for further actions. 
export async function createPaymentIntention({commit}, transactionId) {
	let data = await http.post(config.apiUrl + "/admin/api/lab-order/cart/payment/intent/create?transactionId="+transactionId)
	.then(response => {return response.data})
	console.log(data)
	commit('SET_PAYMENT_INTENTION',data) // use in ./modules/cart.js
}

// use intent id to confirm the payment
export async function confirmPayment(_,paymentIntentId){
	await http.post(config.apiUrl +"/admin/api/lab-order/order/payment/confirm?intentId="+ paymentIntentId)
}

// use intent id to refund
export async function refund(_,paymentIntentId) {
	await http.post(config.apiUrl + "/admin/api/lab-order/order/payment/refund?intentId="+paymentIntentId)
}

// update user's login status
export const updateLoginStatus = ({commit}, loginStatus) => {
	console.log(loginStatus)
	commit('SET_LOGIN', loginStatus);  // use in ./modules/auth.js
}

// get patient's order history
export async function getPatientOrder({commit}){
	let orders = await http.get(config.apiUrl + "/admin/api/lab-order/order/list/patient?page=0&size=11").then(response=>response.data)
	commit('SET_ORDER',orders) // use in ./modeuls/order.js
	console.log(orders)
}

// use order id to get the details of the order
export async function getOrderDetails({commit}, orderId){
	let orderTests = await http.get(config.apiUrl + "/admin/api/lab-order/order/"+orderId)
	.then(response => {
		return response.data
	})
	commit('SET_ORDER_INFO',orderTests) // use in ./modeuls/order.js
	console.log(orderTests)
	return orderTests
}
