<template>
  <div class="container">
    <Header />
    <div class="search-wrapper">
      <input type="text" v-model="search" placeholder="Search test.."/>  <!-- Search Bar-->
    </div>
    <div class="test-cards-container">
      <ProductItem  v-for="test in filteredList"
        v-bind:href="test.name"
        :key="test.id"
        :test="test"   
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductItem from './product/ProductItem.vue';
import Header from './Header.vue'
export default {
  data() {
    return {
      test: null,
      search: '',
    }
  },
  computed: {
    ...mapGetters(['products']),
    filteredList() {
      return this.products.filter(test => {
        return test.name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  components: {
    ProductItem, 
    Header,
  }
}
</script>

<style lang="scss" scoped>
  .test-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 10px;
  }
  .search-wrapper {
    position: relative;
    margin-top: 22px;
    input {
      padding: 3px;
      width: 500px;
      height: 28px;
      color: rgba(0,0,0,.70);
      border: 1px solid rgba(0,0,0,.12);
      transition: .15s all ease-in-out;
      background: white;
      &:focus {
        outline: none;
        transform: scale(1.1);
      }
      &::placeholder{
        font-size: 12px;
        color: rgba(10, 10, 10, 0.5);
        font-weight: 800;
      }
    }
  }
  
</style>

